import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'S. Damji & Sons', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Spice Traders since 1970', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, Welcome To ',
  name: ' S. Damji & Sons',
  subtitle: 'Spice Traders since 1970',
  cta: 'About Us',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "Welcome to S. Damji & Sons , your number one source for all spice needs. We're dedicated to giving you the very best of spices, with a focus on deliverability, quality and cooperation.",
  paragraphTwo:
    'Founded in 1970 by Damji Narshi Soni, S. Damji & Sons  has come a long way from its beginnings in Bombay now to APMC Vashi. When Damji Soni first started out, his drive for quality drove them to start this company so that we at S. Damji & Sons can offer you our services. We now serve customers all over the world, and are thrilled to manoeuvre our passion with you.',
  paragraphThree:
    "We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.",
  resume: 'https://goo.gl/maps/RSNebZWGtzcxopXM9', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'pepper.jpg',
    title: 'Black Pepper',
    info:
      "Black pepper is the world's most traded spice, and is one of the most common spices added to cuisines around the world. Its spiciness is due to the chemical compound piperine, which is a different kind of spicy from the capsaicin characteristic of chili peppers. It is ubiquitous in the modern world as a seasoning, and is often paired with salt and available on dining tables in shakers or mills.",
    info2: ' ',
    url: 'https://en.wikipedia.org/wiki/Black_pepper',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'nutmeg.jpg',
    title: 'Nutmeg',
    info:
      'Nutmeg is the spice made by grinding the seed of the fragrant nutmeg (Myristica fragrans) tree into powder. The spice has a distinctive pungent fragrance and a warm slightly sweet taste; it is used to flavor many kinds of baked goods, confections, puddings, potatoes, sauces, vegetables',
    info2: ' ',
    url: 'https://en.wikipedia.org/wiki/Nutmeg',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mace.jpg',
    title: 'Mace',
    info:
      'Mace is the spice made from the reddish seed covering (aril) of the nutmeg seed. Its flavour is similar to nutmeg but more delicate; it is used to flavour baked goods, vegetables and in preserving and pickling.',
    info2:
      'In the processing of mace, the crimson-colored aril is removed from the nutmeg seed that it envelops and is flattened out and dried for 10 to 14 days. Its color changes to pale yellow, orange, or tan. Whole dry mace consists of flat pieces—smooth, horn-like, and brittle—about 40 mm (1.6 in) long.',
    url: 'https://en.wikipedia.org/wiki/Nutmeg',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },

  {
    id: nanoid(),
    img: 'clove.jpg',
    title: 'Clove',
    info:
      'Cloves are used in the cuisine of Asian, African, Mediterranean, and the Near and Middle East countries, lending flavor to curries, and marinades, as well as fruit (such as apples, pears, and rhubarb). Cloves may be used to give aromatic and flavor qualities to hot beverages, often combined with other ingredients such as lemon and sugar. They are a common element in spice blends, including pumpkin pie spice and speculoos spices.',
    info2:
      'In Mexican cuisine, cloves are best known as clavos de olor, and often accompany cumin and cinnamon. They are also used in Peruvian cuisine, in a wide variety of dishes such as carapulcra and arroz con leche.',
    url: 'https://en.wikipedia.org/wiki/Clove',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },

  {
    id: nanoid(),
    img: 'ginger.jpg',
    title: 'Dry ginger',
    info:
      'Dried ginger is nothing but fresh ginger, which has undergone a drying process. The fresh rhizomes are soaked in water overnight after which the outer peel is carefully removed using a knife or a peeler. They are washed again and then sun-dried on mats or barbecues for around a week. During this period, they are turned periodically. The end result is a pale white dried ginger. Many a times, dried ginger is bleached with calcium carbonate to get a whitish coating, but this is unnecessary and better avoided.',
    info2: ' ',
    url: 'https://en.wikipedia.org/wiki/Ginger',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },

  {
    id: nanoid(),
    img: 'whitep.jpeg',
    title: 'White Pepper',
    info:
      'White pepper is a spice produced from the dried fruit of the pepper plant, Piper nigrum, as is black pepper. It is usually milder than black pepper, with less complex flavor. Both whole and ground white pepper are available.',
    info2:
      'While White & Black pepper come from the berries of the same plant, the difference between white pepper and black pepper starts when the berries are picked. To make black pepper, unripe pepper berries are picked and then they are dried, which blackens the skin and adds flavor elements.',
    url: 'https://www.thespruceeats.com/about-white-pepper-694250',
    // // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would Like To Work With Us?',
  btn: '',
  email: 'sdamji10@yahoo.co.in',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://in.linkedin.com/in/naresh-soni-b08a5560',
    },
    {
      id: nanoid(),
      name: 'map',
      url: 'https://goo.gl/maps/RSNebZWGtzcxopXM9',
    },
    {
      id: nanoid(),
      name: 'yahoo',
      url: 'mailto:sdamji10@yahoo.co.in',
    },
    {
      id: nanoid(),
      name: 'whatsapp',
      url: 'https://api.whatsapp.com/send?phone=919833446566',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
